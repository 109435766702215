import {createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {baseStylesParams, IStylesParams, StylesParamKeys} from '../../baseStylesParams';
import {GridType} from '../../types/galleryTypes';

const stylesParams: IStylesParams = {
  ...baseStylesParams,
  galleryShowFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryShowSort: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  full_width: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: () => GridType.AUTO,
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
